import React from "react";


export class FourOhFourPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  
  render() {
    // const { } = this.state;
    return (
      <div id="FourOhFourPage">
        404 yo
      </div>
    );
  }
}
