import React from "react";

export class NotFound extends React.PureComponent {
  render() {
    return (
      <div >
        <h4>Not Found</h4>
      </div>
    );
  }
}
